
const pdelcSlider = [
    {
        image: '/images/PdelC/pdelc-01.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-02.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-03.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-04.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-05.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-06.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-07.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-08.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-09.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-10.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-11.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-12.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-13.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-14.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-15.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-16.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-17.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-18.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-19.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-20.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-21.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-22.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-23.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-24.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-25.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-26.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-27.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-28.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-29.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-30.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-31.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-32.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-33.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-34.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-35.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-36.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-37.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-38.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-39.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-40.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-41.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-42.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-43.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-44.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-45.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-46.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-47.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-48.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-49.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-50.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-51.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-52.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-53.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-54.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-55.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-56.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-57.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-58.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-59.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-60.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-61.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-62.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-63.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-64.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-65.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-66.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/PdelC/pdelc-67.jpg',
        alt: 'Alberca privada casa Tulum'
    }

]

export default pdelcSlider;