const pdelcMaps =
    [
        //Centro departamento Playa del Carmen
        {
            lat: 20.629640222127154,
            lng: -87.0697782895426
        },
        //Playa Mamitas 20.63083600552918, -87.06505857995535
        {
            lat: 20.63083600552918,
            lng: -87.06505857995535
        },
        //Mall 20.629698588381423, -87.07042700556434
        {
            lat: 20.629698588381423,
            lng: -87.07042700556434
        },
        //5ta Avenida 20.6292144459415, -87.07052867076932
        {
            lat: 20.6292144459415,
            lng: -87.07052867076932
        },


    ];

export default pdelcMaps