const tulumSlider = [
    {
        image: '/images/Tulum/tulum-01.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-02.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-03.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-04.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-05.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-06.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-07.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-08.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-09.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-10.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-11.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-12.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-13.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-14.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-15.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-16.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-17.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-18.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-19.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-20.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-21.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-22.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-23.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-24.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-25.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-26.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-27.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-28.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-29.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-30.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-31.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-32.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-33.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-34.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-35.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-36.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-37.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-38.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-39.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-40.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-41.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-42.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-43.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-44.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-45.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-46.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-47.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-48.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-49.jpg',
        alt: 'Alberca privada casa Tulum'
    },
    {
        image: '/images/Tulum/tulum-50.jpg',
        alt: 'Alberca privada casa Tulum'
    },
]

export default tulumSlider;